<template>
  <transition name="fade-in-up">
    <base-card class="mt-n2 mb-4" inner-class="w-100 fs-0-9em p-3" :color="cardColor" :near-square="true" v-show="active">
      <h6 :class="titleClass">
        <i class="bi bi-exclamation-triangle-fill me-2"></i>
        <span v-html="localIssue.title"></span>
      </h6>
      <span :class="descClass" v-html="localIssue.description"></span>
    </base-card>
  </transition>
</template>

<script>
import { isObject } from 'lodash';
import { QualIssue } from '@/services/QualClasses';
import BaseCard from '@/components/BaseCard.vue';
import ClassList from '@/helpers/ClassList';

export default {
  components: {
    BaseCard
  },
  props: {
    issue: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      localIssue: new QualIssue()
    }
  },
  computed: {
    active() {
      return isObject(this.issue) && this.issue.punishEarly;
    },
    cardColor() {
      if (this.localIssue.type == 'warning') {
        return 'warning';
      } else if (this.localIssue.type == 'error') {
        return 'danger';
      } else {
        return 'info';
      }
    },
    textClass() {
      if (this.cardColor == 'danger' || this.cardColor == 'warning') {
        return 'text-' + this.cardColor + '-dark';
      } else {
        return 'text-' + this.cardColor;
      }
    },
    titleClass() {
      let cl = new ClassList('title');
      cl.addClass(this.textClass);
      return cl.getAll();
    },
    descClass() {
      let cl = new ClassList('description');
      cl.addClass(this.textClass);
      return cl.getAll();
    }
  },
  methods: {
    updateLocalIssue() {
      if (this.issue) {
        this.localIssue = this.issue;
      }
    }
  },
  watch: {
    'issue'() {
      this.updateLocalIssue();
    }
  },
  mounted() {
    this.updateLocalIssue();
  }
}
</script>

<style scoped lang="scss">
.description {
  display: block;
  padding-left: map-get($spacers, 4);
}
</style>