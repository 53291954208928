<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="container-narrow">
      <!-- title above form card -->
      <h1 class="px-4 text-primary fade-in-up-enter-active animation-slowest">{{ greeting }}</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Aan de hand van uw adres kunnen wij de mogelijkheden en prijzen ophalen.</h5>
      <!-- form card -->
      <base-card inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
        <base-form @submit="onSubmit">
          <!-- inputs -->
          <base-input name="PostalCode" placeholder="0000AA"
            v-model:value="lead.address.PostalCode" :validator="v$.address.PostalCode">
            Postcode
          </base-input>
          <div class="d-sm-flex gap-5">
            <base-input name="Street"
              v-model:value="lead.address.Street" :validator="v$.address.Street">
              Straatnaam
            </base-input>
            <base-input name="StreetNumber" fieldWidth="150px"
              v-model:value="lead.address.StreetNumber" :validator="v$.address.StreetNumber">
              Huisnummer
            </base-input>
          </div>
          <base-input name="City" 
            v-model:value="lead.address.City" :validator="v$.address.City">
            Woonplaats
          </base-input>
          <!-- buttons -->
          <button-row class="mt-4">
            <base-button class="ms-auto" icon="bi-arrow-right" type="submit" :disabled="v$.address.$invalid">Volgende</base-button>
          </button-row>
        </base-form>
      <!-- form card end -->
      </base-card>
      <button-row class="mt-2">
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    computed: {
      greeting() {
        if (this.uiStore.isExistingCustomer && !this.v$.address.$invalid) {
          return 'Kloppen deze gegevens?';
        } else {
          return 'Wat is uw adres?';
        }
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.address.$touch();
        return !this.v$.address.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>