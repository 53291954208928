<template>
  <div class="appointment-scheduler">
    <iframe
      class="appointment-scheduler-iframe"
      :src="lead.PrefilledCalendlyUrl"
      frameborder="0">
    </iframe>
  </div>
</template>

<script>
import { useAllStores } from "@/helpers/UseAllStores";
export default {
  setup() {
    return {...useAllStores()};
  },
}
</script>

<style lang="scss" scoped>
  .appointment-scheduler {
    width: 100%;

    .appointment-scheduler-iframe {
      width: 100%;
      height: 770px;
    }
  }
</style>